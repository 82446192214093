import React, { useCallback, useContext, useEffect, useState } from "react";
import "./KeyEventsPage.scss";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import { Container } from "@gitlab-rtsensing/component-library";
import KeyEventsPageHeader from "./header/KeyEventsPageHeader";
import KeyEventsCalendar from "components/key-events-calendar/KeyEventsCalendar";
import {
  CalendarFilters,
  RowItem
} from "components/key-events-calendar/calendarTypes";
import {
  getQuarterEnd,
  getQuarterStart
} from "components/key-events-calendar/calendarUtils";
import UpsertCalendarModal from "components/upsert-calendar-modal/UpsertCalendarModal";
import UpsertCalendarRowModal from "components/upsert-calendar-row-modal/UpsertCalendarRowModal";
import produce from "immer";
import { Alert } from "@opsdti-global-component-library/amgen-design-system";
import { CalendarContext } from "./CalendarContext";
import PaTabNavigation from "components/pa-tab-navigation/PaTabNavigation";
import { getCalendar } from "api/calendar";
import dayjs from "dayjs";
import { getKeyEventsId } from "utils/multitenancy";

const namespace = "rts-pa-key-events-page";

export default function KeyEventsPage(): JSX.Element {
  const isLoaded = useContext(PermissionsContext).isLoaded;
  const keyEventsId = getKeyEventsId();
  const [calendarRowsData, setCalendarRowsData] = useState<RowItem[]>([]);
  const [filters, setFilters] = useState<CalendarFilters>({
    keys: [],
    rows: []
  });
  const [onReorderSave, setOnReorderSave] = useState<(() => void) | undefined>(
    undefined
  );

  const fetchCalendarDefaultDates = useCallback(async () => {
    const calendarData = await getCalendar();
      setFilters(filters => ({
        ...filters,
        startDateMin: calendarData?.defaultDateRange?.length === 2 ? dayjs(calendarData.defaultDateRange[0]) : undefined,
        startDateMax: calendarData?.defaultDateRange?.length === 2 ? dayjs(calendarData.defaultDateRange[1]) : undefined
      }))
  }, []);

  const onDateRangeChange = (dateRange: (dayjs.Dayjs | undefined)[]) => {
    const newFilters = produce(filters, draftState => {
      if (dateRange[0] && dateRange[1]) {
        draftState.startDateMin = getQuarterStart(dateRange[0]);
        draftState.startDateMax = getQuarterEnd(dateRange[1]);
      } else {
        draftState.startDateMin = undefined;
        draftState.startDateMax = undefined;
      }
    });

    setFilters(newFilters);
  }

  useEffect(() => {
    fetchCalendarDefaultDates();
  }, [keyEventsId, fetchCalendarDefaultDates]);

  return (
    <CalendarContext.Provider
      value={{
        calendarRows: calendarRowsData,
        setCalendarRows: setCalendarRowsData,
        calendarFilters: filters,
        setCalendarFilters: setFilters
      }}
    >
      <>
        <div className={namespace}>
          <PermissionsWrapper permissions={isLoaded}>
            <Container className={`${namespace}-container`}>
              <PaTabNavigation />
              <KeyEventsPageHeader
                dateRange={
                  filters.startDateMin && filters.startDateMax
                    ? [filters.startDateMin, filters.startDateMax]
                    : []
                }
                onDateRangeChange={dateRange => {
                  onDateRangeChange(dateRange)
                }}
                onReorderSave={onReorderSave}
              />
              <KeyEventsCalendar
                filters={filters}
                setOnReorderSave={setOnReorderSave}
              />
              <Alert
                className={`${namespace}-alert`}
                content="Yellow emboldened milestone dates indicate that it is subject to change."
                type="warning"
              />
            </Container>
          </PermissionsWrapper>
        </div>
        <PermissionsWrapper permissions={isLoaded}>
          <UpsertCalendarRowModal />
          <UpsertCalendarModal     
                onDateRangeChange={dateRange => {
                  const jsDateRange = [dateRange[0] ? dayjs(dateRange[0]) : undefined, dateRange[1] ? dayjs(dateRange[1]) : undefined]
                  onDateRangeChange(jsDateRange)
                }}
            />

        </PermissionsWrapper>
      </>
    </CalendarContext.Provider>
  );
}
